import { FromExtendedSchema, ExtendedJSONSchema } from "json-schema-to-ts";
import { OpenAPIV3 } from "openapi-types";
import { DateString, Days, EuroCents, Euros, Hours, Meals, Rate, Seconds } from "./units";

type CustomProps = {
    unit:
        | "euros"
        | "eurocents"
        | "hours"
        | "seconds"
        | "days"
        | "meals"
        | "eurocents/meal"
        | "euros/meal"
        | "euros/hour";
};

type JSONSchema = ExtendedJSONSchema<CustomProps>;
type FromSchema<Schema extends JSONSchema> = FromExtendedSchema<
    CustomProps,
    Schema,
    {
        deserialize: [
            {
                pattern: { type: "number"; unit: "euros" };
                output: Euros;
            },
            {
                pattern: { type: "integer"; unit: "eurocents" };
                output: EuroCents;
            },
            {
                pattern: { type: "integer"; unit: "seconds" };
                output: Seconds;
            },
            {
                pattern: { type: "number"; unit: "hours" };
                output: Hours;
            },
            {
                pattern: { type: "number"; unit: "days" };
                output: Days;
            },
            {
                pattern: { type: "integer"; unit: "meals" };
                output: Meals;
            },
            {
                pattern: { type: "integer"; unit: "eurocents/meal" };
                output: Rate<EuroCents, Meals>;
            },
            {
                pattern: { type: "number"; unit: "euros/meal" };
                output: Rate<Euros, Meals>;
            },
            {
                pattern: { type: "number"; unit: "euros/hour" };
                output: Rate<Euros, Hours>;
            },
            {
                pattern: { type: "string"; format: "date" };
                output: DateString;
            },
        ];
    }
>;

/** Recursive Readonly implementation for any (indexable) [[RootState]] such as
 * an array or object */
type ImmutableIndex<T> = Readonly<{
    [K in keyof T]: Immutable<T[K]>;
}>;

export type Immutable<T> = T extends (...args: any[]) => any ? T : T extends object ? ImmutableIndex<T> : T;

type Values<Obj> = Obj[keyof Obj] | never;
type UnionToIntersection<T> = (T extends any ? (x: T) => any : never) extends (x: infer R) => any ? R : never;

export type Components = Immutable<OpenAPIV3.ComponentsObject>;
export type Operation = Immutable<OpenAPIV3.OperationObject>;
export type Reference = Immutable<OpenAPIV3.ReferenceObject>;
export type Parameter = Immutable<OpenAPIV3.ParameterObject>;
export type ResponseBody = Immutable<OpenAPIV3.ResponseObject>;
export type RequestBody = Immutable<OpenAPIV3.RequestBodyObject>;
export type PathItem = Immutable<OpenAPIV3.PathItemObject>;
export type ServerOption = Immutable<OpenAPIV3.ServerObject>;
export type Spec = Immutable<OpenAPIV3.Document>;
export type Method = OpenAPIV3.HttpMethods;

export { OpenAPIV3 };

type Merge<T> =
    | {
          [K in keyof T]: T[K];
      }
    | never;

export type Resolve<Obj, S extends Spec> = Obj extends { $ref: `#/components/${infer CompType}/${infer CompName}` }
    ? S extends { components: { [T in CompType]: { [N in CompName]: infer C } } }
        ? C extends { $ref: string }
            ? never
            : C
        : never
    : Exclude<Obj, Reference>;

export type ResolveAll<T extends readonly unknown[], S extends Spec> = {
    [I in keyof T]: Resolve<T[I], S>;
};

// export type RequestParam<Param> = Param extends {
//     name: infer Name extends string;
//     schema: infer Schema;
//     required?: infer Required extends boolean;
// }
//     ? Required extends true
//         ? { [K in Name]: Schema extends JSONSchema ? FromSchema<Schema> : any }
//         : { [K in Name]?: Schema extends JSONSchema ? FromSchema<Schema> : any }
//     : {};

export type Param<P extends Parameter, S extends Spec> = P extends {
    schema: infer Schema extends JSONSchema;
}
    ? FromSchema<Schema & { readonly components: S["components"] }>
    : P extends {
            content: { "application/json": { schema: infer Schema extends JSONSchema } };
        }
      ? FromSchema<Schema & { readonly components: S["components"] }>
      : any;

export type ServerParams<PP extends readonly Parameter[], S extends Spec> = Merge<
    {
        [P in Extract<PP[number], { required: true } | { schema: { default: any } }> as P["name"]]: Param<P, S>;
    } & {
        [P in Exclude<PP[number], { required: true } | { schema: { default: any } }> as P["name"]]?: Param<P, S>;
    }
>;

export type ClientParams<PP extends readonly Parameter[], S extends Spec> = Merge<
    {
        [P in Extract<PP[number], { required: true }> as P["name"]]: Param<P, S>;
    } & {
        [P in Exclude<PP[number], { required: true }> as P["name"]]?: Param<P, S>;
    }
>;

export type RequestParams<
    Op extends Operation,
    S extends Spec,
    Env extends "client" | "server" = "server",
> = Op extends {
    parameters: infer PP extends readonly (Parameter | Reference)[];
}
    ? Env extends "server"
        ? ServerParams<ResolveAll<PP, S>, S>
        : ClientParams<ResolveAll<PP, S>, S>
    : {};

// export type RequestParams<Op extends Operation, S extends Spec, Obj = {}> = Op extends { parameters: infer Params }
//     ? Params extends readonly [infer First, ...infer Rest]
//         ? RequestParams<Rest, S, First extends Parameter ? Obj & RequestParam<Resolve<First, S>> : Obj>
//         : Merge<Obj>
//     : {};

type JSONContent<R extends ResponseBody | RequestBody, S extends Spec> =
    | (R extends {
          content: { "application/json": { schema: infer Schema extends JSONSchema } };
      }
          ? FromSchema<Schema & { readonly components: S["components"] }>
          : unknown)
    | never;

type ContentTypes<R extends ResponseBody | RequestBody, S extends Spec> = R extends {
    content: infer C extends Record<string, { schema: JSONSchema }>;
}
    ? {
          [Type in keyof C]: FromSchema<C[Type]["schema"] & { readonly components: S["components"] }>;
      }
    : never;

type Response<Op extends Operation, S extends Spec> = Op extends {
    responses: infer Responses extends Record<string, ResponseBody>;
}
    ? Values<{
          [Status in keyof Responses]: Responses[Status] extends { content: unknown }
              ? { status: Status; content: ContentTypes<Exclude<Responses[Status], Reference>, S> }
              : { status: Status };
      }>
    : never;

type Handler<Op extends Operation, S extends Spec, Context> =
    | ((
          params: RequestParams<Op, S>,
          body: Op extends { requestBody: infer B extends RequestBody }
              ? ContentTypes<B, S>["application/json"]
              : undefined,
          context: Context
      ) => Promise<Response<Op, S>>)
    | never;

// type Endpoint<Path extends PathItem, S extends Spec, Context> =
//     | {
//           [M in keyof Path]: Path[M] extends Operation ? Handler<Path[M], S, Context> : never;
//       }
//     | never;

type ToPascalCase<S extends string> = S extends `${infer T}/${infer U}`
    ? `${Capitalize<T>}${ToPascalCase<U>}`
    : Capitalize<S>;

type PathToPascalCase<S extends string> = S extends `/${infer T}` ? ToPascalCase<T> : ToPascalCase<S>;

type Endpoint<Path extends PathItem, S extends Spec, Context, P extends string> =
    | {
          [M in keyof Path & string as Path[M] extends { operationId: string }
              ? Path[M]["operationId"]
              : `${M}${PathToPascalCase<P>}`]: Path[M] extends Operation ? Handler<Path[M], S, Context> : never;
      }
    | never;

// export type Handlers<S extends Spec, Context = {}> =
//     | {
//           [P in keyof S["paths"]]: S["paths"][P] extends PathItem ? Endpoint<S["paths"][P], S, Context> : never;
//       }
//     | never;

export type Handlers<S extends Spec, Context = {}> =
    | Merge<
          UnionToIntersection<
              Values<{
                  [P in keyof S["paths"] & string]: S["paths"][P] extends PathItem
                      ? Endpoint<S["paths"][P], S, Context, P>
                      : never;
              }>
          >
      >
    | never;

export class APIError extends Error {
    constructor(
        public readonly status: number,
        message = ""
    ) {
        super(message);
    }
}

export type ServerUrl<S extends Spec> =
    S extends Immutable<{ servers: ServerOption[] }> ? S["servers"][number]["url"] : string;

type AllComponents = Required<Components>;
type ComponentType<T extends keyof AllComponents> =
    AllComponents[T] extends Record<string, infer R> ? Exclude<R, Reference> : never;

export function resolveComponent<S extends Spec, T extends keyof AllComponents>(
    spec: S,
    obj: any,
    type: T
): ComponentType<T> | undefined {
    if (obj && "$ref" in obj) {
        const name = obj.$ref.split("/").slice(-1);
        return spec.components?.[type]?.[name] as any;
    } else {
        return obj;
    }
}

export type Models<S extends Spec> = S extends {
    components: { schemas: infer Schemas extends Record<string, JSONSchema> };
}
    ? {
          [K in keyof Schemas]: FromSchema<Schemas[K] & { readonly components: S["components"] }>;
      }
    : never;

type ClientActionWithoutBodyAndWithoutResponse<P> = (params: P) => null;

type ClientActionWithBodyAndWithoutResponse<P, B> = (params: P, body: B) => null;

type ClientActionWithoutBodyAndWithResponse<P, C> = UnionToIntersection<
    Values<
        {
            [T in keyof C]: (params: P, type: T) => Promise<C[T]>;
        } & {
            default: (params: P) => Promise<Values<C>>;
        }
    >
>;

type ClientActionWithBodyAndWithResponse<P, B, C> = UnionToIntersection<
    Values<
        {
            [T in keyof C]: (params: P, body: B, type: T) => Promise<C[T]>;
        } & {
            default: (params: P, body: B) => Promise<Values<C>>;
        }
    >
>;

type ClientAction<
    Op extends Operation,
    S extends Spec,
    P = RequestParams<Op, S, "client">,
    R = Response<Op, S>,
> = Op extends { requestBody: infer B extends RequestBody }
    ? R extends { content: infer C }
        ? ClientActionWithBodyAndWithResponse<P, JSONContent<B, S>, C>
        : ClientActionWithBodyAndWithoutResponse<P, JSONContent<B, S>>
    : R extends { content: infer C }
      ? ClientActionWithoutBodyAndWithResponse<P, C>
      : ClientActionWithoutBodyAndWithoutResponse<P>;

type ClientEndpoint<Path extends PathItem, S extends Spec, P extends string> =
    | {
          [M in keyof Path & string as Path[M] extends { operationId: string }
              ? Path[M]["operationId"]
              : `${M}${PathToPascalCase<P>}`]: Path[M] extends Operation ? ClientAction<Path[M], S> : never;
      }
    | never;

export type ClientEndpoints<S extends Spec> =
    | Merge<
          UnionToIntersection<
              Values<{
                  [P in keyof S["paths"] & string]: S["paths"][P] extends PathItem
                      ? ClientEndpoint<S["paths"][P], S, P>
                      : never;
              }>
          >
      >
    | never;
