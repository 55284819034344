import { OpenAPIClient } from "@pentacode/openapi/src/client";
import { api, PentacodeAPI } from "./api";
import { ServerUrl } from "@pentacode/openapi/src/types";

export class PentacodeAPIClient extends OpenAPIClient<PentacodeAPI> {
    constructor(
        baseUrl: ServerUrl<PentacodeAPI>,
        public authToken?: string
    ) {
        super(api, baseUrl);
    }

    async _performRequest(method: string, url: string, body: string, headers: Headers) {
        if (this.authToken) {
            headers.set("Authorization", `Bearer ${this.authToken}`);
        }
        return await super._performRequest(method, url, body, headers);
    }
}
