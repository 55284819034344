import { html } from "lit";
import { colors } from "@pentacode/core/src/colors";
import { PentacodeAPIModels } from "@pentacode/core/src/rest/api";

export function renderFilter(company: PentacodeAPIModels["Company"], filter: PentacodeAPIModels["EntityFilter"]) {
    switch (filter.type) {
        case "venue":
            const venue = company.venues.find((v) => v.id === filter.value);
            if (!venue) {
                return;
            }
            return html`<div class="pill"><i class="people-roof"></i> ${venue.name}</div>`;
        case "department":
            const department = company.venues.flatMap((venue) => venue.departments).find((v) => v.id === filter.value);
            if (!department) {
                return;
            }

            return html`<div
                class="pill"
                style="--color-highlight: ${(department.color && colors[department.color]) || department.color}"
            >
                <i class="people-roof"></i> ${department.name}
            </div>`;
    }
}

export function renderFilters(company: PentacodeAPIModels["Company"], filters: PentacodeAPIModels["EntityFilter"][]) {
    return html` <div class="pills">
        ${filters.length
            ? filters.map((filter) => renderFilter(company, filter))
            : html`<div class="subtle pill">Gesamtes Unternehmen</div>`}
    </div>`;
}
