import { pathToPascalCase } from "./helpers";
import { APIError, Operation, Spec, resolveComponent, ServerUrl, ClientEndpoints } from "./types";

export class OpenAPIClient<S extends Spec> {
    public api: ClientEndpoints<S>;

    protected async _performRequest(method: string, url: string, body: string, headers: Headers) {
        const response = await fetch(url, {
            method,
            body,
            headers,
        });

        return response;
    }

    constructor(
        protected spec: S,
        protected _baseUrl: ServerUrl<S>
    ) {
        this.api = {} as any;

        for (const [path, operations] of Object.entries(spec.paths)) {
            if (typeof operations !== "object") {
                continue;
            }

            for (const [method, operation] of Object.entries(operations) as [string, Operation][]) {
                const handlerName = (operation.operationId ||
                    `${method}${pathToPascalCase(path)}`) as keyof typeof this.api;

                this.api[handlerName] = (async (params: any, body: any, type?: string) => {
                    if (!operation.requestBody) {
                        type = body;
                        body = undefined;
                    }

                    let requestPath = path;
                    const query = new URLSearchParams();
                    const headers = new Headers();
                    headers.append("Content-Type", "application/json");
                    headers.append("Accept", type || "application/json");

                    for (const p of operation.parameters || []) {
                        const param = resolveComponent(spec, p, "parameters");

                        if (!param) {
                            continue;
                        }

                        if (param.name in params) {
                            const value = params[param.name];
                            const stringValue = typeof value === "object" ? JSON.stringify(value) : String(value);

                            switch (param.in) {
                                case "query":
                                    query.set(param.name, stringValue);
                                    break;
                                case "header":
                                    headers.set(param.name, stringValue);
                                    break;
                                case "path":
                                    requestPath = requestPath.replace(`{${param.name}}`, stringValue);
                            }
                        } else if (param.required) {
                            throw new APIError(400, `Missing parameter ${param.name}`);
                        }
                    }

                    const url = `${this._baseUrl}${requestPath}?${query.toString()}`;

                    let content: any;
                    let response: Response | null = null;

                    try {
                        response = await this._performRequest(
                            method,
                            url,
                            typeof body === "string" ? body : JSON.stringify(body),
                            headers
                        );
                    } catch (e) {
                        // If `_performRequest` throws, its' likely due to a network failure. We indicate this
                        // by throwing with a status code of `0`
                        throw new APIError(0, e.message);
                    }

                    const contentType = response.headers.get("Content-Type") || "";

                    try {
                        content = contentType === "application/json" ? await response.json() : response.text();
                    } catch (e) {
                        throw new APIError(500, `Failed to parse response body.`);
                    }

                    if (!response.status.toString().startsWith("2")) {
                        throw new APIError(response.status, typeof content === "string" ? content : content.error);
                    }

                    return content;
                }) as any;
            }
        }
    }
}
